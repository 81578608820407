<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Carrito</div>
      <button
        v-if="
          !isLoading &&
          userQuotables.filter(
            (userQuotable) => userQuotable.saved_for_later === 0
          ).length !== 0 &&
          storefrontStorefront.type === 'b2b'
        "
        :disabled="isLoading"
        @click="generateQuote"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        <span v-if="isLoading" uk-spinner></span>
        <span v-else>Generar cotización</span>
      </button>
      <router-link
        test="go-to-checkout-button"
        v-if="
          !isLoading &&
          userQuotables.filter(
            (userQuotable) => userQuotable.saved_for_later === 0
          ).length !== 0 &&
          storefrontStorefront.type === 'redeem'
        "
        tag="button"
        :disabled="false"
        to="/checkout"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        Ir a checkout
      </router-link>
    </div>

    <UserQuotables
      :userQuotables="userQuotables"
      :isLoading="isLoading"
      :editable="true"
    />

    <div
      class="uk-text-center uk-margin-top"
      v-if="
        !isLoading &&
        userQuotables.filter(
          (userQuotable) => userQuotable.saved_for_later === 0
        ).length !== 0
      "
    >
      <button
        v-if="storefrontStorefront.type === 'b2b'"
        :disabled="isLoading"
        @click="generateQuote"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        <span v-if="isLoading" uk-spinner></span>
        <span v-else>Generar cotización</span>
      </button>
      <router-link
        test="go-to-checkout-button"
        v-if="storefrontStorefront.type === 'redeem'"
        to="/checkout"
        tag="button"
        :disabled="false"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        Ir a checkout
      </router-link>
    </div>

    <div v-if="storefrontStorefront.type === 'b2b'">
      <div class="uk-text-large uk-margin-top">Cotizaciones realizadas</div>
      <div v-if="!isLoading">
        <table
          class="
            uk-table
            uk-table-divider
            uk-table-hover
            uk-table-middle
            uk-visible@s
          "
          v-if="pastQuotes.length !== 0"
        >
          <thead>
            <th width="10%">#</th>
            <th width="20%">Fecha de cotización</th>
            <th class="uk-text-center" width="20%">Código de cotización</th>
            <th class="uk-text-center" width="10%">Cantidad de productos</th>
            <th width="10%" class="uk-text-center">Compartir por correo</th>
            <th width="10%" class="uk-text-center">Descargar</th>
            <th width="20%" class="uk-text-center">Aceptar cotizazión</th>
          </thead>
          <tbody>
            <tr
              v-for="pastQuote in pastQuotes"
              :key="pastQuote.id"
              :style="{
                backgroundColor: isRecent(pastQuote.created_at) ? '#ffd' : '',
              }"
            >
              <td class="uk-text-center uk-text-bold uk-text-large">
                #{{ pastQuote.user_sequence }}
              </td>
              <td>
                <div>{{ formatDatetime(pastQuote.created_at) }}</div>
                <div>(Expira el {{ formatDate(pastQuote.expires_at) }})</div>
              </td>
              <td class="uk-text-center">{{ pastQuote.code }}</td>
              <td class="uk-text-center">
                {{ pastQuote.quotables.length }}
              </td>
              <td class="uk-text-center">
                <div
                  v-if="hasntExpired(pastQuote.expires_at) && pastQuote.file"
                >
                  <button
                    class="uk-button uk-button-primary uk-border-rounded"
                    uk-icon="mail"
                  ></button>
                  <div uk-drop="mode: click">
                    <div
                      class="
                        uk-card
                        uk-padding-small
                        uk-card-secondary
                        uk-border-rounded
                      "
                    >
                      <ValidationObserver v-slot="{ invalid }">
                        <ValidationProvider
                          v-for="(email, index) in shareToEmails"
                          :key="'email-' + index"
                          name="Correo destinatario"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <div class="uk-margin-top uk-text-left">
                            <span
                              v-if="shareToEmails.length !== 1"
                              uk-icon="icon: minus-circle; ratio: 1"
                              class="cursor-pointer"
                              @click="removeShareEmail(index)"
                            ></span>
                            Correo destinatario
                          </div>
                          <input
                            class="uk-input uk-border-rounded"
                            type="text"
                            name="Correo destinatario"
                            v-model="shareToEmails[index]"
                          />
                          <span>{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="uk-margin-small-top">
                          <button
                            class="
                              uk-button uk-button-primary uk-border-rounded
                            "
                            @click="addShareEmail"
                          >
                            Agregar correo
                          </button>
                        </div>

                        <div class="uk-margin-small-top">
                          <button
                            class="
                              uk-button uk-button-primary uk-border-rounded
                            "
                            :disabled="invalid || isLoading"
                            @click="shareQuote(pastQuote)"
                          >
                            <span v-if="isLoading" uk-spinner></span>
                            <span v-else> Enviar </span>
                          </button>
                        </div>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </td>
              <td class="uk-text-center">
                <a
                  v-if="pastQuote.file"
                  :href="pastQuote.file.path"
                  target="_blank"
                  class="uk-button uk-button-primary uk-border-rounded"
                >
                  <span uk-icon="cloud-download"></span>
                </a>
              </td>
              <td class="uk-text-center">
                <router-link
                  v-if="pastQuote.valid"
                  :to="'/quotes/resolution/' + pastQuote.id"
                  class="uk-button uk-button-primary uk-border-rounded"
                  uk-icon="play"
                ></router-link>
                <div v-else>Expirada</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="uk-text-center">
          No se han realizado cotizaciones
        </div>
      </div>
      <div v-else class="uk-text-center">
        <div uk-spinner></div>
      </div>
    </div>

    <div v-if="storefrontStorefront.type === 'redeem'">
      <div class="uk-text-large uk-margin-top">Compras realizadas</div>
      <div v-if="!isLoading">
        <table
          class="
            uk-table
            uk-table-divider
            uk-table-hover
            uk-table-middle
            uk-visible@s
          "
          v-if="pastRedeems.length !== 0"
        >
          <thead>
            <th width="30%">Fecha de compra</th>
            <th class="uk-text-center" width="10%">Código</th>
            <th class="uk-text-center" width="20%">Cantidad de productos</th>
            <th class="uk-text-center" width="20%">Total</th>
            <th width="20%"></th>
          </thead>
          <tbody>
            <tr v-for="pastRedeem in pastRedeems" :key="pastRedeem.id">
              <td>
                {{ formatDatetime(pastRedeem.created_at) }}
              </td>
              <td class="uk-text-center">
                {{ pastRedeem.code }}
              </td>
              <td class="uk-text-center">
                {{
                  pastRedeem.redeem_items.reduce(
                    (accum, item) => accum + item.quantity,
                    0
                  )
                }}
              </td>
              <td class="uk-text-center">
                {{
                  formatThousands(
                    pastRedeem.redeem_items.reduce(
                      (accum, item) => accum + item.quantity * item.point_price,
                      0
                    )
                  )
                }}
                {{ pointsName }}
              </td>
              <td>
                <router-link
                  :to="'/redeem/' + pastRedeem.id"
                  tag="button"
                  :disabled="false"
                  class="uk-button uk-button-primary uk-border-rounded"
                >
                  Ver compra
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UIkit from "uikit";
import { mapGetters } from "vuex";
import UserQuotables from "@/views/Utils/UserQuotables";

export default {
  name: "QuotesIndex",

  components: {
    UserQuotables,
  },

  data() {
    return {
      model: {
        quantity: null,
        faces: null,
        colors: null,
      },
      shareToEmails: [""],
      selectedUserQuotable: null,
      mode: "edit",
      isLoading: true,
      userQuotables: [],
      pastQuotes: [],
      pastRedeems: [],
    };
  },

  computed: {
    ...mapGetters(["storefrontStorefront", "pointsName"]),
  },

  mounted() {
    this.getPayload();
  },

  methods: {
    isRecent(timestamp) {
      return moment().diff(moment(timestamp), "minutes") < 30;
    },
    getPayload() {
      this.isLoading = true;
      this.axios.get("/quotes").then(({ data: { userQuotables } }) => {
        this.userQuotables = userQuotables;
      });
      if (this.storefrontStorefront.type === "b2b") {
        this.axios
          .get("/quotes/past-quotes")
          .then(({ data: { pastQuotes } }) => {
            this.pastQuotes = pastQuotes;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      if (this.storefrontStorefront.type === "redeem") {
        this.axios
          .get("/quotes/past-redeems")
          .then(({ data: { pastRedeems } }) => {
            this.pastRedeems = pastRedeems;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    hasntExpired(date) {
      return moment.utc(date).isAfter(moment());
    },
    addShareEmail() {
      this.shareToEmails.push("");
    },
    removeShareEmail(index) {
      this.shareToEmails.splice(index, 1);
    },
    shareQuote(quote) {
      this.isLoading = true;
      this.axios
        .post("/quotes/" + quote.id + "/share", {
          emails: this.shareToEmails,
        })
        .then(() => {
          this.shareToEmails = [""];
          UIkit.notification({
            message: "Se ha enviado la cotización",
            status: "success",
            pos: "bottom-left",
          });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    generateQuote() {
      this.isLoading = true;
      this.axios
        .get("/quotes/generate")
        .then(({ data: { pastQuotes } }) => {
          this.userQuotables = [];
          this.pastQuotes = pastQuotes;

          window.open(this.pastQuotes[0].file.path, "_blank").focus();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
